import React from "react"
import Link from "gatsby-link"
import Img from "gatsby-image"
import TruncateMarkup from "react-truncate-markup"

import Card from "react-bootstrap/Card"

import "./newsletter-preview.module.scss"

const NewsletterPreview = ({ post, ...otherProps }) => {
  return (
    <Link to={`/newsletter/${post.slug}`} className="card" styleName="wrapper">
      {post.heroImage && post.heroImage.sizes && (
        <Img
          className="card-img-top"
          styleName="img"
          title={post.title}
          sizes={post.heroImage.sizes}
        />
      )}
      <Card.Body styleName="body">
        <Card.Title styleName="title">
          <TruncateMarkup lines={2}>
            <h5>{post.title}</h5>
          </TruncateMarkup>
        </Card.Title>
        <p styleName="subtitle">
          <TruncateMarkup lines={2}>
            <span>{post.subtitle}</span>
          </TruncateMarkup>
        </p>
      </Card.Body>
    </Link>
  )
}

export default NewsletterPreview
